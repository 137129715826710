import {
  CollabAmbassadorModel,
  CollabCampaignAssetMediaInterface,
  CollabCampaignCategoryInterface,
  CollabCampaignConfigurationInterface,
  CollabCampaignCostInterface,
  CollabCampaignForecastInterface,
  CollabCampaignKeyAudienceInterface,
  CollabCampaignKeyMetricsInterface,
  CollabCampaignResumeInterface,
  ContestModel,
  UserModel
} from '@api/models/';
import { CollabAmbassadorsPaginationInterface } from '@collab/collab.service';
import { CacheService } from '@services/cache/cache.service';
import { ModelFactoryService } from '@services/model-fectory/model-factory.service';
import { ResourceService } from '@services/resource/resource.service';
import Axios from 'axios';
import moment from 'moment';
import { serialize } from 'object-to-formdata';
import { CollabCampaignInterface } from './';

export class CollabCampaignModel extends ContestModel implements CollabCampaignInterface {

  user: UserModel | CollabAmbassadorModel = null;
  weeks = 0;
  endDataCapture = null;
  country = null;

  tasksDone = 0;
  tasksOpen = 0;

  videosPendingApproval = 0;

  resume: CollabCampaignResumeInterface = {
    all: {
      all: {
        audience: 0,
        comments: 0,
        creators: 0,
        engagement: 0,
        posts: 0,
        reactions: 0,
        interactions: 0,
        qualified_views: 0,
        shares: 0,
        views: 0,
        reach: 0,
        audience_engagement: 0,
        future_interest_rate: 0,
        interest_rate: 0,
        irregular_views: 0,
        realized_recurring_rate: 0,
        share_rate: 0,
        total_views: 0,
        unique_engagement: 0,
        view_total_time: 0,
        avg_watch_time: 0,
        potential_view_time: 0,
      }
    }
  };

  configuration: CollabCampaignConfigurationInterface = {};
  forecast: CollabCampaignForecastInterface = {};
  categories: CollabCampaignCategoryInterface[] = [];
  keyMetrics: CollabCampaignKeyMetricsInterface = {};
  audience: CollabCampaignKeyAudienceInterface = {};
  cost: CollabCampaignCostInterface = {};

  creators: CollabAmbassadorModel[] = [];
  creatorsById: { [key: number]: CollabAmbassadorModel } = {};

  assets?: CollabCampaignAssetMediaInterface[] = [];
  assetsById: { [key: number]: CollabCampaignAssetMediaInterface } = {};

  tmpAssetsUploadSignature: { formAttributes, formInputs } = null; // local
  tmpAssetsUploadSignatureTimeout = null; // local

  constructor(model?: CollabCampaignInterface) {
    // console['logger'].log('collab-campaign.model->constructor(): model', model);
    super(model);
    // console.log('collab-campaign.model->constructor(): model', model, this.resume);
    this.fill(model);
  }

  override beforeFill(data: CollabCampaignInterface) {

    // to ensure we dont overwrite the entire "resume" object, lets merge it...
    if (data.resume && this.resume) {

      // console.log('collab-campaign.model->beforeFill(): this.resume', this, this.resume);

      if (data.resume.all.all && this.resume.all.all) {
        data.resume.all.all = { ...this.resume.all.all, ...data.resume.all.all };
      }
    }

    // startDate
    if (data.startDate) {
      data.startDate = data.startDate.replace('+00:00', '-05:00');
    }

    // endDate
    if (data.endDate) {
      data.endDate = data.endDate.replace('+00:00', '-05:00');
    }

    // endDataCapture
    if (data.endDataCapture) {
      data.endDataCapture = data.endDataCapture.replace('+00:00', '-05:00');
    }

    // updatedAt
    if (data.updatedAt) {
      data.updatedAt = data.updatedAt.replace('+00:00', '-05:00');
    }

    // createdAt
    if (data.createdAt) {
      data.createdAt = data.createdAt.replace('+00:00', '-05:00');
    }

    // configuration
    if (data.configuration) {

      data.configuration = { ...this.configuration, ...data.configuration };

      // createdAt
      if (data.configuration.createdAt) {
        data.configuration.createdAt = data.configuration.createdAt.replace('+00:00', '-05:00');
      }

      // updatedAt
      if (data.configuration.updatedAt) {
        data.configuration.updatedAt = data.configuration.updatedAt.replace('+00:00', '-05:00');
      }

    }

    super.beforeFill(data);

  }

  override afterFill(model) {

    try {

      // console['logger'].log('collab-campaign.model->afterFill(): model', model);

      if (model.user) {
        model.user = ModelFactoryService.instance.userFactory.build(model.user);
      }

      const startDate = moment(model.startDate);
      // console['logger'].log('collab-campaign.model->afterFill(): startDate', startDate);

      const endDate = moment(model.endDate);
      // console['logger'].log('collab-campaign.model->afterFill(): endDate', endDate);

      this.weeks = endDate.diff(startDate, 'week');
      // console['logger'].log('collab-campaign.model->afterFill(): this.weeks', this.weeks);

      super.afterFill(model);

      // console['logger'].log('collab-campaign.model->afterFill(): this', this);

    }
    catch (e) {
      console.error('collab-campaign.model->afterFill(): ERROR', e);
    }

  }

  getResume() {
    return this.resume.all.all;
  }

  /**
   * Method collab to save the campaign brief as draft
   */
  async saveBrief(data): Promise<CollabCampaignModel> {
    console['logger'].log('collab-campaign.model->getKeyMetrics()');

    try {

      console['logger'].log('collab-campaign.model->getKeyMetrics(): data', data);

      data.dos = {
        positive: '',
        negative: ''
      };

      const response = await ResourceService.instance.put('collabApi', {
        resource: 'collab-campaigns',
        path: `/campaigns/${this.id}/brief`,
        data: serialize(data)
      });
      console['logger'].log('collab-campaign.model->getKeyMetrics(): response', response);

      return this;

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method collab to complete the campaign brief
   */
  async completeBrief(): Promise<CollabCampaignModel> {
    console['logger'].log('collab-campaign.model->completeBrief()');

    try {

      const response = await ResourceService.instance.put('collabApi', {
        resource: 'collab-campaigns',
        path: `/campaigns/${this.id}/complete-brief`
      });
      console['logger'].log('collab-campaign.model->completeBrief(): response', response);

      return this;

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method collab to get campaign forecast
   */
  async getForecast(params: { channel? } = {}, fromCache = true, expiration?): Promise<CollabCampaignForecastInterface> {
    console['logger'].log('collab-campaign.model->getForecast()');

    try {

      const response = await ResourceService.instance.get('collabApi', {
        resource: 'collab-campaigns',
        path: `/campaigns/${this.id}/forecast`,
        expiration,
        params,
        fromCache
      });
      console['logger'].log('collab-campaign.model->getForecast(): response', response);

      this.forecast = response;

      return this.forecast;

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method collab to get campaign forecast
   */
  async getCreatorsInvited(params: { limit?, page?, status?, term?, channel? }, fromCache = true, expiration?): Promise<CollabAmbassadorsPaginationInterface> {

    console['logger'].log('collab-campaign.model->getCreatorsInvited(): params', params);
    console['logger'].log('collab-campaign.model->getCreatorsInvited(): fromCache', fromCache);

    params = params || {};
    params.limit = params.limit || 20;

    try {

      const response = await ResourceService.instance.get('collabApi', {
        resource: 'collab-creators',
        path: `/campaigns/${this.id}/invites`,
        expiration,
        fromCache,
        params
      });
      console['logger'].log('collab-campaign.model->getCreatorsInvited(): response', response);

      const creators = ModelFactoryService.instance.collabAmbassadorFactory.buildCollection(response.items);
      console['logger'].log('collab-campaign.model->getCreatorsInvited(): creators', creators);

      if (params.page === 1) {
        this.creatorsById = {};
        this.creators = [];
      }

      for (const creator of creators) {
        this.creatorsById[creator.id] = creator;
        this.creators.push(creator);
      }

      return {
        items: this.creators,
        totalItems: response.totalItems,
        page: response.page,
        limit: response.limit
      };

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method collab to get campaign categories details
   */
  async getCategories(): Promise<CollabCampaignCategoryInterface[]> {
    console['logger'].log('collab-campaign.model->getCategories()');

    try {

      const { categories } = await ResourceService.instance.get('collabApi', {
        resource: 'collab-categories',
        path: `/categories`
      });
      console['logger'].log('collab-campaign.model->getCategories(): categories', categories);
      console['logger'].log('collab-campaign.model->getCategories(): this.configuration.categories', this.configuration.categories);

      return categories.filter(item => {
        return this.configuration.categories.indexOf(item.id) > -1;
      });

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method collab to add creators to campaign
   */
  async addCreators(creatorsIds: number[]) {
    try {
      console['logger'].log('collab-campaign.model->addCreators(): creatorsIds', creatorsIds);

      const response = await ResourceService.instance.post('collabApi', {
        resource: 'collab-creators',
        path: `/campaigns/${this.id}/pre-invite`,
        data: { users: creatorsIds }
      });
      console['logger'].log('collab-campaign.model->addCreators(): response', response);

      return response;

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method collab to remove creator from campaign
   */
  async removeCreator(creatorId: number) {
    try {
      console['logger'].log('collab-campaign.model->removeCreator(): creatorId', creatorId);

      if (!creatorId) {
        throw new Error(`'creatorId' must be provided`);
      }

      const response = await ResourceService.instance.delete('collabApi', {
        resource: 'collab-creators',
        path: `/users/${creatorId}/campaigns/${this.id}`
      });

      const creator = this.creatorsById[creatorId];
      this.creators.splice(this.creators.indexOf(creator), 1);
      delete this.creatorsById[creatorId];

      console['logger'].log('collab-campaign.model->removeCreator(): response', response);

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method collab to reject creator for campaign
   */
  async rejectCreator(creatorId: number) {
    try {
      console['logger'].log('collab-campaign.model->rejectCreator(): creatorId', creatorId);

      if (!creatorId) {
        throw new Error(`'creatorId' must be provided`);
      }

      const response = await ResourceService.instance.put('collabApi', {
        resource: 'collab-creators',
        path: `/users/${creatorId}/campaigns/${this.id}/reject`
      });

      console['logger'].log('collab-campaign.model->rejectCreator(): response', response);

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method collab to send the invites to creators
   */
  async sendCreatorsInvites() {
    try {
      console['logger'].log('collab-campaign.model->sendCreatorsInvites()');

      const response = await ResourceService.instance.put('collabApi', {
        resource: 'collab-creators',
        path: `/campaigns/${this.id}/invite`
      });

      CacheService.instance.createResource('collab-campaigns');

      console['logger'].log('collab-campaign.model->sendCreatorsInvites(): response', response);

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method collab user audience
   */
  async getAudience(): Promise<CollabCampaignKeyAudienceInterface> {
    console['logger'].log('collab-campaign.model->getAudience()');

    try {

      const response = await ResourceService.instance.get('collabApi', {
        resource: 'collab-creators',
        path: `/campaigns/${this.id}/audience`,
        expiration: { hours: 1 }
      });
      console['logger'].log('collab-campaign.model->getAudience(): response', response);

      const newResponse = {};

      for (const i in response) {
        if (i.indexOf('_') !== 0 && !Array.isArray(response[i])) {

          newResponse[i] = [];
          console['logger'].log('collab-campaign.model->getAudience(): response[i]', i, response[i]);

          for (const x in response[i]) {
            // console['logger'].log('collab-campaign.model->getAudience(): response[i]', i, x, response[i][x]);

            if (x.indexOf('_') !== 0) {
              newResponse[i].push({ description: x, percent: response[i][x] });
            }
          }

        }
      }

      this.audience = newResponse;
      console.log('collab-campaign.model->getAudience(): this.audience', this.audience);

      return this.audience;

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method collab get assets
   */
  async getAssets(fromCache?): Promise<CollabCampaignAssetMediaInterface[]> {
    console['logger'].log('collab-campaign.model->getAssets()');

    try {

      const response = await ResourceService.instance.get('collabApi', {
        resource: 'collab-assets',
        fromCache,
        path: `/campaigns/assets/${this.id}/all`
      });
      console['logger'].log('collab-campaign.model->getAssets(): response', response);

      this.assets = response;
      // this.assetsById = {};

      for (const asset of this.assets) {

        asset.fileType = asset.mimeType.split('/')[0];
        asset.fileExt = asset.mimeType.split('/')[1];

        // this.assetsById[asset.id] = asset;

      }

      console.log('collab-campaign.model->uploadAsset(): this.assets', this.assets);

      return response;

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method collab upload asset
   */
  async uploadAsset(media: CollabCampaignAssetMediaInterface): Promise<CollabCampaignAssetMediaInterface> {
    console['logger'].log('collab-campaign.model->uploadAsset()');

    try {

      if (this.tmpAssetsUploadSignatureTimeout) {
        clearTimeout(this.tmpAssetsUploadSignatureTimeout);
      }

      if (!this.tmpAssetsUploadSignature) {

        const response = await ResourceService.instance.post('collabApi', {
          resource: 'collab-s3-signature',
          path: `/campaigns/assets/${this.id}/create-link`,
          preventDuplicates: false
        });

        this.tmpAssetsUploadSignature = { formAttributes: response.formAttributes, formInputs: response.formInputs };
        console.log('collab-campaign.model->uploadAsset(): this.tmpAssetsUploadSignature', this.tmpAssetsUploadSignature);

      }

      const { formAttributes, formInputs } = this.tmpAssetsUploadSignature;
      console.log('collab-campaign.model->uploadAsset(): { formAttributes, formInputs }', { formAttributes, formInputs });

      const signatureData = new FormData();

      for (let i in formInputs) {
        signatureData.append(i, formInputs[i]);
      }

      signatureData.append('file', media['file']);

      await Axios({
        method: formAttributes.method,
        url: formAttributes.action,
        data: signatureData
      });

      const formData = new FormData();

      formData.append('name', media.name);
      formData.append('bucketKey', formInputs['key']);
      formData.append('filename', media['file'].name);
      formData.append('filetype', media['file'].type);

      const response = await ResourceService.instance.post('collabApi', {
        resource: 'collab-assets',
        path: `/campaigns/assets/${this.id}`,
        data: formData,
        preventDuplicates: false,
        showError: false
      });
      console['logger'].log('collab-campaign.model->uploadAsset(): response', response);

      response.fileType = response.mimeType.split('/')[0];
      response.fileExt = response.mimeType.split('/')[1];

      /*if (!this.assetsById[response.id]) {
       this.assetsById[response.id] = response;
       this.assets.push(response);
       }*/

      console.log('collab-campaign.model->uploadAsset(): this.assets', this.assets);

      this.tmpAssetsUploadSignatureTimeout = setTimeout(() => {
        this.tmpAssetsUploadSignature = null;
      }, 10000);

      return response;

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method collab delete assets
   */
  async deleteAsset(assetId) {
    console['logger'].log('collab-campaign.model->deleteAsset()');

    try {

      const response = await ResourceService.instance.delete('collabApi', {
        resource: 'collab-assets',
        path: `/campaigns/assets/${this.id}/${assetId}`
      });
      console['logger'].log('collab-campaign.model->deleteAsset(): response', response);

      /*const asset = this.assetsById[assetId];
       this.assets.splice(this.assets.indexOf(asset), 1);
       delete this.assetsById[assetId];*/

      console['logger'].log('collab-campaign.model->deleteAsset(): this.assetsById', assetId, this.assetsById);
      console['logger'].log('collab-campaign.model->deleteAsset(): this.assets', assetId, this.assets);

      return true;

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method collab download creators contact info
   */
  async downloadCreatorsContactInfo() {
    console.log('collab-campaign.model->downloadCreatorsContactInfo()');

    try {

      const response = await ResourceService.instance.downloadFile('collabApi', {
        resource: 'collab-contact-info',
        filename: `campaign-${this.hashtag.hashtag ? this.hashtag.hashtag : this.id}_creators-contact-info.xls`,
        path: `/campaigns/${this.id}/address/spreadsheet`
      });
      console.log('collab-campaign.model->downloadCreatorsContactInfo(): response', response);

      return true;

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method collab download report XLSX
   */
  async downloadReportXLSX() {
    console.log('collab-campaign.model->downloadReportXLSX()');
    try {

      const response = await ResourceService.instance.downloadFile('collabApi', {
        resource: 'collab-report-xlsx',
        filename: `campaign-${this.hashtag.hashtag ? this.hashtag.hashtag : this.id}_report.xls`,
        path: `/campaigns/${this.id}/report/spreadsheet`
      });
      console.log('collab-campaign.model->downloadReportXLSX(): response', response);

      return true;
    }
    catch (e) {
      console.log('collab-campaign.model->downloadReportXLSX(): ERROR', e);
      throw e;
    }
  }

  /**
   * Method collab download creators XLSX
   */
  async downloadCreatorsXLSX() {
    console.log('collab-campaign.model->downloadCreatorsXLSX()');
    try {

      const response = await ResourceService.instance.downloadFile('collabApi', {
        resource: 'collab-creators-xlsx',
        filename: `campaign-${this.hashtag.hashtag ? this.hashtag.hashtag : this.id}_creators.xls`,
        path: `/campaigns/${this.id}/creators/spreadsheet`
      });
      console.log('collab-campaign.model->downloadCreatorsXLSX(): response', response);

      return true;
    }
    catch (e) {
      console.log('collab-campaign.model->downloadCreatorsXLSX(): ERROR', e);
      throw e;
    }
  }

}
